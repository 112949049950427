const HomeAbout = () => {
    return (
        <div className="section bg-dark overflow-hidden">
            <div className="about-section section-padding mt-n3 mb-n1">
                <div
                    className="about-images"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/port-2.jpg)`,
                    }}
                ></div>

                <div className="container">
                    <div
                        className="row justify-content-end"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h3 className="title">Calvin Scott Todd</h3>
                                <p className="about-text">
                                    Software Engineer, Pythonista, Sommelier
                                </p>
                                <p>
                                    There are few things more important in this
                                    modern world than a competenent Software
                                    Engineer and a good glass of wine. One has a
                                    world of depth, is strong, cultured, and
                                    experienced. The other complains about
                                    JavaScript and spends more time on Stack
                                    Overflow than writing unit tests. <br />
                                    <br /> Just kidding, I have never met a
                                    bottle of Claret that compains about JS.
                                </p>
                                <p>
                                    I have studied English and Creative Writing
                                    at Viriginia Tech. I have studied wine in
                                    California alongside all levels of
                                    Sommeliers. I most recently finished a
                                    Post-Baccalaureate B.S. in Computer Science
                                    at Orgeon State University (with a 4.0), and
                                    am currently enrolled in Georgia Tech&apos;s
                                    OMSCS with a focus on Machine Learning.
                                    Software design, development, implementation
                                    is something I thoroughly enjoy. I am lucky
                                    enough to have had a lot of unique
                                    experiences in my life so far, truly blessed
                                    in fact. But I have always come back to tech
                                    and software design. It is what I love. From
                                    the analytical problem solving, to the truly
                                    creative code solutions, software
                                    engineering is more than just a career to
                                    me.
                                </p>
                                <img
                                    className="signechar"
                                    src="./images/head.png"
                                    alt=""
                                />
                                <h6 className="name">C.S. Todd</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
