import { Link } from "react-router-dom";
import PageTitle from "../../components/page-title";
import ProjectFilter from "../../components/project/project-filter";
import ProjectItem from "../../components/project/project-item";
import ProjectDataHomepage from "../../data/project-homepage.json";
import useMasonry from "../../hooks/use-masonry";
import { slugify } from "../../utils";

const ProjectContainerHomepage = () => {
    const { categories } = useMasonry(
        ProjectDataHomepage,
        ".project-list",
        ".masonry-grid",
        ".messonry-button",
        ".messonry-button li"
    );
    return (
        <div className="section section-padding-03">
            <div className="container">
                <a href="/project">
                    <PageTitle title="Recent Projects" />
                </a>
            </div>

            <div className="custom-container container-fluid">
                <div className="project-section mt-n4">
                    <div className="row grid project-list">
                        {ProjectDataHomepage &&
                            ProjectDataHomepage.map((project) => (
                                <div
                                    key={project.id}
                                    className={`col-xl-4 col-md-6 masonry-grid ${project.categories
                                        .map((cat) => slugify(cat))
                                        .join(" ")}`}
                                >
                                    <ProjectItem data={project} />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectContainerHomepage;
